<template>
  <div class="login">
    <div class="loginForm">
      <p class="title">
        用户登录
      </p>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入账号"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            type="password"
            autocomplete="new-password"
            placeholder="请输入密码"
          />
        </el-form-item>
      </el-form>
      <el-button
        class="loginBtn"
        @click="login"
      >
        登录
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Login',
    data(){
        return{
            form:{
                username:'',
                password:''
            },
            rules:{
                username:{required:true, message:"请输入账号", trigger:"blur"},
                password:{required:true, message:"请输入密码", trigger:"blur"}
            },
            isLogin: false
        }
    },
    methods:{
        login(){
            this.$refs.form.validate((valid)=>{
                if(valid){
                    let userList = JSON.parse(localStorage.getItem('userList'))
                    userList.map((item)=>{
                        if(item.username === this.form.username){
                            if(item.password === this.form.password){
                                this.$message.success('登录成功')
                                localStorage.setItem('loginName',this.form.username)
                                this.isLogin = true
                                this.$router.push('/')

                            }
                        }
                    })
                    console.log( JSON.parse(localStorage.getItem('userList')),'userList');
                    if(!this.isLogin){this.$message.error('账号或密码错误')}
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.login{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: steelblue;
}
.loginForm{
    width: 440px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 0 29px 3px #0f5299;
    border-radius: 6px;
}
.loginBtn{
    width: 100%;
    background: #0062ff;
    color: #fff;
    font-size: 16px;
    margin-top: 15px;
}
.title{
    font-size: 30px;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
}
</style>